import { HEADER_TYPES } from '../constants';

export default ({ elMainHeader, elTopbar, elSubheader }) => ({
  ...(elTopbar && {
    [HEADER_TYPES.TOPBAR]: {
      type: HEADER_TYPES.TOPBAR,
      element: elTopbar,
    },
  }),
  [HEADER_TYPES.MAIN]: {
    type: HEADER_TYPES.MAIN,
    element: elMainHeader,
  },
  ...(elSubheader && {
    [HEADER_TYPES.SUBHEADER]: {
      type: HEADER_TYPES.SUBHEADER,
      element: elSubheader,
    },
  }),
});
