import dom from '../../wrapper/DomWrapper';

class GoogleAnalytics {
  constructor({ gaTrackingId, gtmContainerId }) {
    this.gaTrackingId = gaTrackingId;
    this.gtmContainerId = gtmContainerId;
  }

  init = () => {
    const { head } = dom.document;

    if (this.gaTrackingId) {
      const analyticScriptEl = this.createAnalyticScript(this.gaTrackingId);
      head.insertAdjacentElement('afterbegin', analyticScriptEl);
      const analyticSnippetEl = this.createAnalyticSnippet(this.gaTrackingId);
      head.insertAdjacentElement('afterbegin', analyticSnippetEl);
    }

    if (this.gtmContainerId) {
      const gtmSnippetEl = this.createGoogleTagManagerSnippet();
      const gtmNoScriptEl = this.createGoogleTagManagerNoScript();
      head.insertAdjacentElement('afterbegin', gtmSnippetEl);
      dom.document.body.insertAdjacentElement('afterbegin', gtmNoScriptEl);
    }
  }

  createAnalyticScript = (trackingId) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

    return script;
  };

  createAnalyticSnippet = (trackingId) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${trackingId}');
    `;

    return script;
  };

  /**
   * Create script snippet according to this docs
   * https://developers.google.com/tag-manager/quickstart
   */
  createGoogleTagManagerSnippet = () => {
    const script = dom.createElement('script');
    script.type = 'text/javascript';
    script.text = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${this.gtmContainerId}');
  `;

    return script;
  };

  /**
   * Create script snippet according to this docs
   * https://developers.google.com/tag-manager/quickstart
   */
  createGoogleTagManagerNoScript = () => {
    const noscriptEl = dom.createElement('noscript');
    const iframeEl = dom.createElement('iframe');
    iframeEl.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmContainerId}`;
    iframeEl.height = 0;
    iframeEl.width = 0;
    iframeEl.style.display = 'none';
    iframeEl.style.visibility = 'hidden';
    noscriptEl.appendChild(iframeEl);

    return noscriptEl;
  };
}

export default GoogleAnalytics;
