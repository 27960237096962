export const COOKIE_NOTICE_TOOLBAR = '#cookie-notice-toolbar';
export const COOKIE_TOOLBAR_SETTINGS = '.cookie-toolbar__btn_settings';
export const COOKIE_TOOLBAR_ACCEPT = '.cookie-toolbar__btn_accept';

export const COOKIE_MODAL = '#cookie-notice-modal';
export const COOKIE_MODAL_HREF = '[href^="#cookie-notice-popup"]';
export const COOKIE_MODAL_SAVE = '.cookie-settings-popup__btn-save';

export const GDPR_ANALYTICS_SWITCHER = '.gdpr-switcher__analytics';
export const GDPR_CHECKBOX = '.gdpr-switcher__checkbox';
export const GDPR_TEXT = '.gdpr-switcher__text';

export const MODAL_CLOSE_CLASS = 'modal__close';
export const MODAL_OVERLAY_CLASS = 'modal__overlay';

export const HIDE_SCROLL_BODY_CLASS = 'hide-scroll';
export const FADE_OUT_ANIMATION_DURATION = 300;
export const EU_COUNTRIES_LIST = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

export const ANALYTICS_COOKIE_KEY = 'sp_cc_a';

export const PLATFORM_ANALYTICS_COOKIE_KEYS = [
  '_spAnalyticsId',
  '_spAnalyticsIdF',
  '_spAnalyticsIdFHash',
  '_spAnalyticsIdHash',
];

export const COOKIE_REMOVE_TIMEOUT = 2000;

export const COOKIE_SETTINGS_ANALITYCS_DEFAULTS = {
  isEuro: 0,
  global: 1,
};

export const ESC_KEY_CODE = 27;

export const COOKIE_LIGHT_BG_COLOR = 'rgba(231, 231, 231, 0.95)';
