import snakeCase from 'lodash/snakeCase';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';

import dom from '../../../../wrapper/DomWrapper';
import router from '../../../../ecommerce/ecwid/custom/router';
import {
  ADD_TO_CART,
  ASPECT_RATIO,
  BACKGROUND_COLORS,
  GO_TO_CHECKOUT,
  HOVER_TARGETS,
  NAME,
  NAME_OR_PHOTO,
  OPEN_PRODUCT_PAGE,
  PHOTO,
} from '../../../../../components/Ecommerce/Ecwid/Custom/constants';

import { lightness, toValidColor } from '../../../../helpers/color';

import ecommerce from '../../../../ecommerce/ecwid/custom';
import { isCssVar } from '../../../../helpers/color/utils';
import lazyLoad from '../../../LazyLoad';
import browser from '../../../../helpers/browser';

const ANIMATION_DELAY = 50;
const ANIMATION_TIME = 2500;
const LABEL_CHANGE_TIME = 1000;

class SingleProduct {
  element = null;

  model = {};

  settings = {};

  constructor(element, model) {
    this.element = element;
    this.model = model;
    this.settings = element.dataset.settings ? JSON.parse(element.dataset.settings) : {};
    this.elBuyButton = dom.getElement('.buy-button', element);
  }

  init = (index = 0) => {
    this.applyBackground(index);
    this.applyHoverEffect();
    this.checkCart();
    this.initBuyButton();

    ecommerce.provider.cart.onChange.add(this.checkCart);
  };

  checkCart = async () => {
    const count = await ecommerce.provider.cart.itemGetQuantity(this.model.id);

    if (count) {
      dom.addClass(this.element, '_added');
    } else {
      dom.removeClass(this.element, '_added');
    }

    this.updateButtonLabel();
  };

  initBuyButton = async () => {
    const { buttonAction } = this.settings;

    if (!this.elBuyButton) return;

    this.isAdding = false;
    dom.on(this.elBuyButton, 'click', async () => {
      const count = await ecommerce.provider.cart.itemGetQuantity(this.model.id);

      if (this.isAdding) return;
      if (this.model.cantAddToCartMore(count)) {
        router.goToCartPage();
        return;
      }
      if (!this.model.isAvailable()) {
        router.goToProductPage(this.model.id, this.model.name);
        return;
      }

      const setAddingDelayed = debounce(() => {
        this.isAdding = true;
        dom.addClass(this.elBuyButton, 'btn_spinner');
      }, 100);

      const blink = () => {
        clearTimeout(this.blinkTimeout);
        dom.removeClass(this.elBuyButton, '_active');
        setTimeout(() => {
          dom.addClass(this.elBuyButton, '_active');
          this.labelChangeTimeout = setTimeout(() => {
            this.fixLabel = false;
            this.updateButtonLabel();
          }, LABEL_CHANGE_TIME);
          this.blinkTimeout = setTimeout(() => {
            dom.removeClass(this.elBuyButton, '_active');
          }, ANIMATION_TIME);
        }, ANIMATION_DELAY);
      };

      const addToCart = async (callback) => {
        setAddingDelayed();

        try {
          await ecommerce.provider.cart
            .itemAdd(this.model.id, 1, {}, (isAdded) => {
              setTimeout(
                () => {
                  this.isAdding = false;
                  dom.removeClass(this.elBuyButton, 'btn_spinner');
                  blink();

                  if (!isAdded) return;

                  if (!dom.hasClass(this.element, '_added')) {
                    dom.addClass(this.element, '_added');
                  }

                  if (isFunction(callback)) callback();
                },
                this.isAdding ? 500 : 0,
              );
              setAddingDelayed.cancel();
            });
        } catch (e) {
          setAddingDelayed.cancel();
          setTimeout(
            () => {
              this.isAdding = false;
              dom.removeClass(this.elBuyButton, 'btn_spinner');
              // TODO Show product adding error
            },
            this.isAdding ? 500 : 0,
          );
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };

      switch (buttonAction) {
        case ADD_TO_CART:
          this.fixLabel = true;
          await addToCart();
          break;
        case OPEN_PRODUCT_PAGE:
          router.goToProductPage(this.model.id, this.model.name);
          break;
        case GO_TO_CHECKOUT:
          this.fixLabel = true;
          await addToCart(() => router.goToCartPage());
          break;
        default:
          break;
      }
    });
  };

  updateButtonLabel = async () => {
    if (this.fixLabel) return;

    clearTimeout(this.labelChangeTimeout);
    const { buttonLabels } = this.settings;

    if (!this.elBuyButton) return;

    const count = await ecommerce.provider.cart.itemGetQuantity(this.model.id);
    let label;

    if (this.model.isOutOfStock()) {
      label = buttonLabels.details;
    } else if (!this.model.cantAddToCartMore(count)) {
      label = buttonLabels.buy;
    } else {
      label = buttonLabels.checkout;
    }

    dom.addHtml(dom.getElement('.buy-text', this.elBuyButton), label);
  };

  applyBackground = (index) => {
    const aspectRatioFloat = ASPECT_RATIO[this.settings.product_list_image_aspect_ratio];
    const images = dom.getCollection('.product__img > img', this.element);
    const backgroundColor = images && images.length
      ? 'transparent'
      : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length];
    const imageContainerStyle = {
      backgroundColor,
      height: 0,
      paddingTop: `calc(100% / ${aspectRatioFloat})`,
    };
    dom.updateStyle(dom.getElement('.product__img', this.element), imageContainerStyle);

    const { colors } = this.settings;
    // eslint-disable-next-line no-nested-ternary
    const color = colors && colors.background && !lightness(colors.background)
      ? (isCssVar(colors.colorContrast) ? toValidColor(colors.colorContrast) : colors.colorContrast)
      : (isCssVar(colors.color) ? toValidColor(colors.color) : colors.color);
    dom.updateStyle(this.element, { color });

    if (browser.isIe()) [...images].forEach((image) => image.setAttribute('data-object-fit', 'cover'));

    setTimeout(() => lazyLoad(), 0);
  };

  applyHoverEffect = () => {
    if (!this.element) return;

    const {
      hasDetails, hoverEffect, hoverTarget, hasEffect, productTarget,
    } = this.settings;

    if (hasEffect && hoverTarget) {
      const attachHoverEffects = (target) => {
        if (!target) return;

        dom.on(target, 'mouseenter', () => {
          dom.addClass(this.element, `_hover_${snakeCase(hoverTarget)}`);
          dom.addClass(this.element, `_effect_${snakeCase(hoverEffect)}`);
        });
        dom.on(target, 'mouseleave', () => {
          dom.removeClass(this.element, `_hover_${snakeCase(hoverTarget)}`);
          dom.removeClass(this.element, `_effect_${snakeCase(hoverEffect)}`);
        });
      };
      // eslint-disable-next-line default-case
      switch (hoverTarget) {
        case HOVER_TARGETS.product:
          attachHoverEffects(this.element);
          break;
        case HOVER_TARGETS.nameOrPhoto:
          attachHoverEffects(dom.getElement('._name', this.element));
          attachHoverEffects(dom.getElement('._image', this.element));
          break;
        case HOVER_TARGETS.name:
          attachHoverEffects(dom.getElement('._name', this.element));
          break;
        case HOVER_TARGETS.photo:
          attachHoverEffects(dom.getElement('._image', this.element));
          break;
      }
    }

    if (!hasDetails) return;
    if ([NAME_OR_PHOTO, NAME].includes(productTarget)) {
      this.attachOpenProductPageHandler(dom.getElement('.product-row._name', this.element));
    }
    if ([NAME_OR_PHOTO, PHOTO].includes(productTarget)) {
      this.attachOpenProductPageHandler(dom.getElement('.product-row._image', this.element));
    }
  };

  attachOpenProductPageHandler = (el) => {
    if (!el) return;

    dom.on(el, 'click', () => {
      router.goToProductPage(this.model.id, this.model.name);
    });
  };
}

export default SingleProduct;
