import dom from '../../wrapper/DomWrapper';
import { checkIsClickOnPinterest } from '../PinterestButton/utils';
import {
  DEFAULT_SELECTORS,
  FADE_OUT_ANIMATION_DURATION,
  GALLERY_CONTAINER_SELECTOR,
  LIGHT_BOX_TEMPLATE,
  HIDE_SCROLL_BODY_CLASS,
  NAVIGATIONS_BUTTONS_STYLES,
} from './constants';
import {
  ESC_KEY_CODE,
  LEFT_KEY_CODE,
  RIGHT_KEY_CODE,
} from '../../constants';

class LightBox {
  constructor(selectors = {}) {
    this.photoModalData = {};
    this.galleryModalData = {};
    this.navFunc = {
      prev: () => null,
      next: () => null,
    };

    this.selectors = { ...DEFAULT_SELECTORS, ...selectors };

    this.createTemplateLightBox();
    this.handleCloseOnOutsideClick();
  }

  createTemplateLightBox = () => {
    const elLightBoxModal = dom.createElement('div');

    dom.addHtml(elLightBoxModal, LIGHT_BOX_TEMPLATE(this.selectors));
    dom.addClass(elLightBoxModal, 'lightBox-modal');
    dom.document.body.appendChild(elLightBoxModal);
  };

  handleCloseOnOutsideClick = () => {
    const {
      modalContainer,
      arrayNavElements,
      navButtonsContainers,
    } = this.getLightBoxAttributes();

    dom.on(
      dom.getElement(`#${this.selectors.modalContainerId}`),
      'click',
      this.handleClickToCloseModal(
        modalContainer,
        arrayNavElements,
        navButtonsContainers,
      ),
    );
  };

  handleKeyDown = (event) => {
    const {
      modalContainer,
    } = this.getLightBoxAttributes();
    const { keyCode } = event;

    switch (keyCode) {
      case ESC_KEY_CODE:
        this.hideLightBox(modalContainer);
        break;
      case LEFT_KEY_CODE:
        this.navFunc.prev();
        break;
      case RIGHT_KEY_CODE:
        this.navFunc.next();
        break;
      default:
        break;
    }
  }

  getLightBoxElements = ({
    modalContainerId,
    lightBoxImgId,
    nextBottomId,
    prevBottomId,
    navBotContPrevId,
    navBotContNextId,
    svgBottomPrevId,
    svgBottomNextId,
  } = DEFAULT_SELECTORS) => ({
    elModalContainer: dom.getElement(`#${modalContainerId}`),
    elLightBoxImg: dom.getElement(`#${lightBoxImgId}`),
    elNextBottom: dom.getElement(`#${nextBottomId}`),
    elPrevBottom: dom.getElement(`#${prevBottomId}`),
    elNavBotContPrev: dom.getElement(`#${navBotContPrevId}`),
    elNavBotContNext: dom.getElement(`#${navBotContNextId}`),
    elSvgBottomPrev: dom.getElement(`#${svgBottomPrevId}`),
    elSvgBottomNext: dom.getElement(`#${svgBottomNextId}`),
  });

  addLightBoxToGalleryPhotos = (
    galleryList,
    navButtonsAttributes = {},
    modalsParams = {},
  ) => {
    const {
      modalContainer,
      lightBoxImg,
      addCursorPointerToElement,
      handleImageClick,
    } = modalsParams;
    const {
      viewNavButtons,
      navButtonsHandleClick,
      navButtonsContainers: { navBotContPrev, navBotContNext },
    } = navButtonsAttributes;

    // The cycle goes through all the galleries on the page
    Object.keys(galleryList).forEach((galleryHash) => {
      const gallery = this.galleryModalData[galleryHash];
      const elGalleryContainer = dom.getElement(GALLERY_CONTAINER_SELECTOR(galleryHash));
      const imagesCollection = dom.getCollection('.gallery-item', elGalleryContainer);
      const photoIdList = Array.from(imagesCollection).map((item) => item.id);

      const viewNavButtonsControl = viewNavButtons(
        gallery,
        photoIdList,
      );
      const clickToNavButtons = navButtonsHandleClick(
        gallery,
        photoIdList,
        lightBoxImg,
        modalContainer,
      );

      // Using the map, we bypass each image in the gallery
      // and add event handlers for each image separately
      photoIdList.forEach((id, index) => {
        const element = dom.getElement(`[id="${id}"]`, elGalleryContainer);
        const galleryPhoto = gallery[id];

        if (!element) return;

        dom.on(element, 'click', (event) => {
          let currentIndex = index;
          viewNavButtonsControl(currentIndex);
          handleImageClick(galleryPhoto, modalContainer, lightBoxImg, event);

          const next = () => {
            const validCurrentIndex = currentIndex + 1;
            viewNavButtonsControl(validCurrentIndex);

            if (gallery[photoIdList[validCurrentIndex]]) {
              currentIndex = validCurrentIndex;
            }

            clickToNavButtons(currentIndex);
          };
          const prev = () => {
            const validCurrentIndex = currentIndex - 1;
            viewNavButtonsControl(validCurrentIndex);

            if (gallery[photoIdList[validCurrentIndex]]) {
              currentIndex = validCurrentIndex;
            }

            clickToNavButtons(currentIndex);
          };

          navBotContNext.removeEventListener('click', this.navFunc.next);
          navBotContPrev.removeEventListener('click', this.navFunc.prev);

          dom.on(navBotContNext, 'click', next);
          dom.on(navBotContPrev, 'click', prev);

          this.navFunc = {
            next,
            prev,
          };
        });

        addCursorPointerToElement(element);
      });
    });
  };

  addLightBoxToPhotos = (
    photosList,
    {
      modalContainer,
      lightBoxImg,
      addCursorPointerToElement,
      handleImageClick,
    },
  ) => {
    Object.keys(photosList).forEach((id) => {
      let element = dom.getElement(`[id="${id}"]`);
      const photo = photosList[id];

      if (!element || !photo) return;

      const isCloned = element.closest('.slick-cloned');

      if (isCloned) {
        element = dom.getElement(`.glide__slide:not(.slick-cloned) [id="${id}"]`);
      }

      dom.on(element, 'click', (event) => handleImageClick(photo, modalContainer, lightBoxImg, event));
      addCursorPointerToElement(element);
    });
  };

  setNavButtonsVisibility = (
    gallery,
    photosId,
  ) => (currentIndex) => {
    dom.window.requestAnimationFrame(
      () => {
        const navNextStyle = gallery[photosId[currentIndex + 1]]
          ? NAVIGATIONS_BUTTONS_STYLES.show
          : NAVIGATIONS_BUTTONS_STYLES.hide;

        const navPrevStyle = gallery[photosId[currentIndex - 1]]
          ? NAVIGATIONS_BUTTONS_STYLES.show
          : NAVIGATIONS_BUTTONS_STYLES.hide;

        setTimeout(() => {
          dom.updateStyle(`#${this.selectors.navBotContNextId}`, navNextStyle);
          dom.updateStyle(`#${this.selectors.navBotContPrevId}`, navPrevStyle);
        }, 0);
      },
    );
  };

  disableScroll = () => dom.addClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);

  enableScroll = () => dom.removeClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);

  hideLightBox = (modalContainer, navBotContPrev, navBotContNext) => {
    dom.window.requestAnimationFrame(() => {
      dom.fadeOut(modalContainer, FADE_OUT_ANIMATION_DURATION, () => {
        dom.updateStyle(navBotContPrev, {
          display: 'none',
        });
        dom.updateStyle(navBotContNext, {
          display: 'none',
        });

        dom.removeClass(modalContainer, '_white');
        dom.removeClass(modalContainer, '_dark');

        this.enableScroll();
        this.resetLightBoxImageSrc();
      });
    });

    dom.off(dom.document, 'keydown', this.handleKeyDown);
  };

  handleClickToCloseModal = (
    modalContainer,
    arrayNavElements,
    navButtonsContainers,
  ) => (event) => {
    if (
      !arrayNavElements.find((el) => el === event.target)
      && !dom.hasClass(modalContainer, '_fade-out')
    ) {
      const { navBotContPrev, navBotContNext } = navButtonsContainers;
      this.hideLightBox(modalContainer, navBotContPrev, navBotContNext);
    }
  };

  resetLightBoxImageSrc = () => {
    const elLightBoxImage = dom.getElement(`#${this.selectors.lightBoxImgId}`);

    if (elLightBoxImage instanceof HTMLElement) elLightBoxImage.removeAttribute('src');
  };

  navButtonsHandleClick = (
    gallery,
    photosId,
    lightBoxImg,
    modalContainer,
  ) => (currentIndex) => {
    const {
      lightbox_theme = '', //eslint-disable-line
      src = '',
    } = gallery[photosId[currentIndex]];

    this.resetLightBoxImageSrc();

    dom.addClass(modalContainer, lightbox_theme);
    lightBoxImg.setAttribute('src', src);
  };

  handleImageClick = (photoModalData, modalContainer, lightBoxImg, event) => {
    const {
      lightbox_theme = '', //eslint-disable-line
    } = photoModalData;

    if (checkIsClickOnPinterest(event)) return;

    lightBoxImg.setAttribute('src', photoModalData.src);
    dom.addClass(modalContainer, lightbox_theme);

    dom.fadeIn(modalContainer);

    this.disableScroll();

    dom.on(dom.document, 'keydown', this.handleKeyDown);
  };

  addCursorPointerStyle = (element) => {
    const oldStyles = element.getAttribute('style') || '';
    element.setAttribute('style', `cursor:pointer;${oldStyles}`);
  };

  getLightBoxAttributes() {
    const {
      elModalContainer,
      elLightBoxImg,
      elNextBottom,
      elPrevBottom,
      elNavBotContPrev,
      elNavBotContNext,
      elSvgBottomPrev,
      elSvgBottomNext,
      arrayNavElements = [
        elNextBottom,
        elPrevBottom,
        elNavBotContPrev,
        elNavBotContNext,
        elSvgBottomPrev,
        elSvgBottomNext,
        elLightBoxImg,
      ],
    } = this.getLightBoxElements(this.selectors);

    const modalsParams = {
      modalContainer: elModalContainer,
      lightBoxImg: elLightBoxImg,
      addCursorPointerToElement: this.addCursorPointerStyle,
      handleImageClick: this.handleImageClick,
    };
    const navButtonsContainers = {
      navBotContPrev: elNavBotContPrev,
      navBotContNext: elNavBotContNext,
    };
    const navButtonsAttributes = {
      viewNavButtons: this.setNavButtonsVisibility,
      navButtonsHandleClick: this.navButtonsHandleClick,
      navButtonsContainers,
    };

    return {
      navButtonsAttributes,
      modalsParams,
      modalContainer: elModalContainer,
      arrayNavElements,
      navButtonsContainers,
    };
  }

  connectAllWidgetToLightBox() {
    const { navButtonsAttributes, modalsParams } = this.getLightBoxAttributes();

    this.addLightBoxToPhotos(this.photoModalData, modalsParams);

    this.addLightBoxToGalleryPhotos(
      this.galleryModalData,
      navButtonsAttributes,
      modalsParams,
    );
  }
}

export default LightBox;
