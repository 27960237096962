import dom from '../../../wrapper/DomWrapper';

export default (link) => {
  if (!link) return true;

  const { origin: currentSourceOrigin } = dom.window.location;
  const currentPageUrl = new URL(currentSourceOrigin);
  const anchorUrl = new URL(link, currentPageUrl);
  const { origin: anchorSourceOrigin } = anchorUrl;
  const isTheSameSiteLink = currentSourceOrigin === anchorSourceOrigin;

  return isTheSameSiteLink;
};
