import dom from '../../wrapper/DomWrapper';

class EmbedCode {
  constructor(embedBlock) {
    this.embedBlock = embedBlock;
  }

  init = () => {
    if (!this.embedBlock) return;

    const iframe = dom.getElement('iframe', this.embedBlock);

    if (!iframe) return;

    dom.on(iframe, 'load', this.onIframeLoaded);
  };

  onIframeLoaded = () => {
    dom.removeClass(this.embedBlock, 'show_spinner');
  }
}

export default EmbedCode;
