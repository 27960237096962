import get from 'lodash/get';
import set from 'lodash/set';
import isUndefined from 'lodash/isUndefined';
import { appendToHead } from './utils';

const globalOptionsState = [];

export default (parentType, path, value) => {
  if (isUndefined(value)) return;

  let currentSchemaIndex = globalOptionsState.findIndex((option) => get(option, ['@type'], null) === parentType);

  if (currentSchemaIndex < 0) {
    globalOptionsState.push({ '@type': parentType });
    currentSchemaIndex = globalOptionsState.length - 1;
  }

  const currentTypeOptions = globalOptionsState[currentSchemaIndex];

  set(currentTypeOptions, path, value);

  const textOptions = JSON.stringify(currentTypeOptions);

  appendToHead(textOptions);
};
