export const DEFAULT_STORE_URL = 'store';

export const PRODUCT_PAGE = 'PRODUCT_PAGE';

export const CART_PAGE = 'CART_PAGE';

export const CATALOG_PAGE = 'CATALOG_PAGE';

export const PAGE_TYPE = { PRODUCT_PAGE, CART_PAGE, CATALOG_PAGE };

export const GET_PRODUCT_PAGE_REGEX = (storeUrl) => new RegExp(`/${storeUrl}/\\S+-p[0-9]+(\\/?)+(\\?|#|$)`, 'i');

export const GET_CART_PAGE_REGEX = (storeUrl) => new RegExp(`/${storeUrl}/(checkout|cart)`, 'i');

export const GET_CATALOG_PAGE_REGEX = (storeUrl) => new RegExp(`(/${storeUrl}(\\/)?)`, 'gm');

export const PRODUCT_PAGE_WRAPPER_ID = 'js-product-page-wrapper';

export const STOREFRONT_PAGE_WRAPPER_ID = 'js-storefront-page-wrapper';

export const CATALOG_PAGE_WRAPPER_ID = 'js-catalog-page-wrapper';
