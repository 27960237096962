import dom from '../../../wrapper/DomWrapper';

export default (options) => {
  let script = dom.getElement('#schemaOrgScript');

  if (!script) {
    script = dom.createElement('script');
    script.id = 'schemaOrgScript';
    script.setAttribute('type', 'application/ld+json');
  }

  script.textContent = options;
  dom.document.head.appendChild(script);
};
