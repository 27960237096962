import defaultImage from '../Image/utils';

export const INSTAGRAM_IMAGE_PLACEHOLDER_COUNT = 6;
export const INSTAGRAM_IMAGE_CLASS_NAME = 'instagram-item';
export const INSTAGRAM_IMAGE_CAPTION_CLASS_NAME = 'instagram-caption';

export const INSTAGRAM_CAPTION_TEMPLATE = (caption = '') => (
  `<div class="${INSTAGRAM_IMAGE_CAPTION_CLASS_NAME}">${caption}</div>`
);
export const INSTAGRAM_IMAGE_TEMPLATE = (
  permalink,
  src,
  caption,
  targetBlank = true,
  showCaption = false,
  className = '',
) => (
  `<div class="_error-wrap">
    <a 
      class="${className}"
      href="${permalink}"
      target="${targetBlank ? '_blank' : '_self'}"
    >
      <img src="${src}" alt=""/>
    </a>
    ${showCaption ? INSTAGRAM_CAPTION_TEMPLATE(caption) : ''}
  </div>
`);

export const INSTAGRAM_IMAGE_PLACEHOLDER_TEMPLATE = (
  src = defaultImage,
) => (
  `<div class="_error-wrap">
    <div
      class="instagram-thumb instagram-thumb_unavailable cropped square"
    >
      <img src="${src}" alt=""/>
    </div>
  </div>
`);
