import dom from '../../../wrapper/DomWrapper';
import { PINTEREST_SRC } from '../constants';

export default () => {
  const [documentScript] = dom.document.getElementsByTagName('SCRIPT');
  const pinterestScript = dom.document.createElement('SCRIPT');

  pinterestScript.type = 'text/javascript';
  pinterestScript.async = true;
  pinterestScript.src = PINTEREST_SRC;

  documentScript.parentNode.insertBefore(pinterestScript, documentScript);
};
