import ProviderWrapper from './Wrapper';

class VideoVimeo extends ProviderWrapper {
  init() {
    const {
      id, isBackground,
    } = this.dataParams;

    this.renderVideoOnPage();
    this.player = this.connectVideoDefault();
    this.addPreviewImage(this.player);

    this.player.source = {
      type: 'video',
      sources: [
        {
          src: id,
          provider: 'vimeo',
        },
      ],
    };

    this.player.config.vimeo = {
      ...this.player.config.vimeo,
      speed: !isBackground, // Video must be hosted by a PRO account or higher
      portrait: !isBackground,
      title: !isBackground,
    };

    this.player.elements.poster.style.display = 'none';
  }
}

export default VideoVimeo;
