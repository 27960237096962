const getProductId = (href) => {
  const idPartRegex = /p[0-9]+(\/?)+(\?|#|$)/gm;

  if (href.search(idPartRegex) === -1) return null;

  const idPart = href.match(idPartRegex)[0];
  const productId = idPart.replace(/[?p/#]/ig, '');

  return parseInt(productId, 10) || null;
};

export default getProductId;
