import dom from '../../wrapper/DomWrapper';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';

import Parallax from './Parallax';

import { RESIZE_TIMEOUT } from './constants';

const instances = [];
const init = () => {
  const elements = dom.getCollection('.section_bg__with-effect');

  [...elements].forEach((el) => instances.push(new Parallax(el)));
};
const handleScroll = () => instances.forEach((instance) => {
  instance.handleScroll();
});
const handleResize = () => instances.forEach((instance) => {
  instance.handleResize();
});
const events = () => {
  dom.on(dom.window, 'scroll', handleScroll);
  subscribeToDebouncedResizeChanging(dom.window, handleResize, RESIZE_TIMEOUT);
};

export default () => {
  init();
  events();
};
