import dom from '../../../../wrapper/DomWrapper';

import {
  CatalogPage, ProductPage, CartPage, DASHBOARD_CONTAINER_ID,
} from '../../../../implementation/Ecommerce/ecwid/native';

import {
  isCartPage,
  isCatalogPage,
  isProductPage,
} from './utils';

import {
  DEFAULT_STORE_URL,
  PAGE_TYPE,
} from './constants';

import getProductId from './utils/getProductId';
import getStateValue from '../../../../helpers/getStateValue';

const WIDGET_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.PRODUCT_PAGE, new ProductPage(DASHBOARD_CONTAINER_ID)],
  [PAGE_TYPE.CART_PAGE, new CartPage(DASHBOARD_CONTAINER_ID)],
  [PAGE_TYPE.CATALOG_PAGE, new CatalogPage(DASHBOARD_CONTAINER_ID)],
]);

class Router {
  static goToCatalogPage = () => {
    Router.setPath();
  }

  static goToCartPage = () => {
    Router.setPath('cart');
  }

  static goToProductPage = (id, name) => {
    Router.setPath(`${name.replace(/[^a-zA-Z0-9]/g, '-')}-p${id}`);
  }

  static setPath = (path = '') => {
    const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

    dom.window.location.pathname = `${storeUrl}/${path}`;
  }

  constructor() {
    this.href = dom.window.location.href;
    this.type = this.getPageType();
  }

    init = () => {
      if (!WIDGET_BY_PAGE_TYPE.has(this.type)) return;

      const page = WIDGET_BY_PAGE_TYPE.get(this.type);

      page.init(this.getProductId());
    }

    getPageType = () => {
      const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

      if (isCartPage(this.href, storeUrl)) return PAGE_TYPE.CART_PAGE;

      if (isProductPage(this.href, storeUrl)) return PAGE_TYPE.PRODUCT_PAGE;

      if (isCatalogPage(this.href, storeUrl)) return PAGE_TYPE.CATALOG_PAGE;

      return null;
    }

    getProductId = () => {
      if (this.type !== PAGE_TYPE.PRODUCT_PAGE) return null;

      return getProductId(this.href);
    }
}

export default Router;
