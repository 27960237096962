import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

class Disqus extends WidgetWrapper {
  init = () => {
    this.elDisqusWidget = dom.getElement(this.selector);
    this.isLoaded = false;
    this.throttleSpeed = 0;

    if (!this.elDisqusWidget) return;

    const { disqusAccount, postId } = this.elDisqusWidget.dataset;

    if (disqusAccount) {
      this.createAndAppendScript(postId, disqusAccount);
      this.handleIframe();
    }
  }

  createAndAppendScript = (postId, disqusAccount) => {
    const script = document.createElement('script');
    const disqusBlock = document.getElementById('disqus_thread');
    const pageUrl = window.location.href;

    script.type = 'text/javascript';
    script.innerHTML = this.getScriptCode({ pageUrl, postId, disqusAccount });
    disqusBlock.appendChild(script);
  }

  handleIframe = () => {
    if (this.isLoaded) {
      clearTimeout(this.disqusTimeout);
      this.handleShowDisqus();

      return;
    }

    this.disqusTimeout = setTimeout(() => {
      if (dom.getElementHeight(this.elDisqusWidget) > 0) {
        this.isLoaded = true;
      }

      this.throttleSpeed += 1;
      this.handleIframe();
    }, 500);
  }

  handleShowDisqus = () => {
    setTimeout(() => {
      const widgetHeight = dom.getElementHeight(this.elDisqusWidget);

      const isValid = widgetHeight > 100;

      if (isValid) {
        dom.showOpacity(this.elDisqusWidget);
      } else {
        dom.hide(this.elDisqusWidget);
      }
    }, this.throttleSpeed * 1000);
  }

  getScriptCode = ({ pageUrl, postId, disqusAccount }) => `let disqus_config = function() {
                                          this.page.url = '${pageUrl}';
                                              this.page.identifier = '${postId}';
                                        };

                                        (function() {
                                              let script = document.createElement('script');
                                                script.src = 'https://${disqusAccount}.disqus.com/embed.js';
                                                script.setAttribute('data-timestamp', +new Date());
                                                (document.head || document.body).appendChild(script);
                                              })();
                                              `
}

export default Disqus;
