import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';
import { NAV, SOCIAL_SELECTOR } from '../constants';

export default (elements) => {
  if (isEmpty(elements)) return elements;

  const first = [];
  const last = [];
  const rest = [];

  elements.forEach((element) => {
    const isMenuElement = !!dom.getElement(NAV, element);
    const isSocialElement = !!dom.getElement(SOCIAL_SELECTOR, element);

    if (isMenuElement) first.push(element);
    else if (isSocialElement) last.push(element);
    else rest.push(element);
  });

  return [...first, ...rest, ...last];
};
