import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isElement from 'lodash/isElement';
import isPlainObject from 'lodash/isPlainObject';

import dom from '../../wrapper/DomWrapper';

import {
  EFFECTS,
  PARALLAX_MULTIPLIER,
  PARALLAX_UPDATE_ACTION,
} from './constants';

import {
  checkElementVisibility,
  checkEffectByName,
  getEffectByName,
} from './utils';

class Parallax {
  constructor(elSection) {
    if (!isElement(elSection)) return;

    const elImage = dom.getElement('.section_bg-img', elSection);

    if (!isElement(elImage)) return;

    this.data = {};
    this.imageData = {};
    this.effects = {};
    this.elSection = elSection;
    this.elImage = elImage;
    this.imageMinHeight = null;
    this.imageTranslate = { y: 0 };
    this.imageIsLoaded = false;

    this.init();
  }

  static get scrollTop() {
    return dom.window.pageYOffset || dom.document.documentElement.scrollTop;
  }

  static get windowHeight() {
    return dom.window.innerHeight;
  }

  static getPreparedEffects(effects) {
    const parallax = checkEffectByName(effects, EFFECTS.PARALLAX);
    const fixed = checkEffectByName(effects, EFFECTS.FIXED);
    const active = getEffectByName(effects);

    return {
      active,
      parallax,
      fixed,
    };
  }

  init() {
    const effects = this.elSection.getAttribute('data-effects');

    if (!isNil(effects)) {
      this.effects = this.constructor.getPreparedEffects(JSON.parse(effects));
    }

    const image = new Image();

    image.onload = ({ target }) => {
      const { naturalWidth, naturalHeight } = target;

      this.imageData = {
        naturalWidth,
        naturalHeight,
      };
      this.imageIsLoaded = true;
      this.applyImageEffect({ action: PARALLAX_UPDATE_ACTION });
    };
    image.src = this.elImage.dataset.src;
  }

  updateData() {
    const { scrollTop, windowHeight } = this.constructor;
    const sectionBound = this.elSection.getBoundingClientRect();
    const isVisible = checkElementVisibility({
      scrollTop,
      windowHeight,
      elementBound: sectionBound,
    });

    this.data = {
      isVisible,
      scrollTop,
      windowHeight,
      sectionBound,
    };

    return this.data;
  }

  updateImageMinHeight({ action } = {}) {
    const imageMinHeight = this.getActualImageMinHeight({
      action,
    });

    if (imageMinHeight === this.imageMinHeight) return null;

    dom.updateStyle(this.elImage, {
      minHeight: `${imageMinHeight}px`,
    });

    this.imageMinHeight = imageMinHeight;

    return this.imageMinHeight;
  }

  updateImageTranslate() {
    const imageTranslate = this.getImageTranslate();

    if (
      !isPlainObject(imageTranslate)
      || isEqual(imageTranslate, this.imageTranslate)
    ) return null;

    const { y } = imageTranslate;
    const transform = `translate3D(-50%, ${y}px, 0)`;

    dom.updateStyle(this.elImage, {
      transform,
    });

    this.imageTranslate = {
      ...(isPlainObject(this.imageTranslate) && this.imageTranslate),
      ...imageTranslate,
    };

    return this.imageTranslate;
  }

  getActualImageMinHeight({ action } = {}) {
    let { imageMinHeight } = this;

    if (action !== PARALLAX_UPDATE_ACTION) return imageMinHeight;

    const {
      windowHeight,
      sectionBound: { height: sectionHeight = 0 } = {},
    } = this.data;
    const { parallax, fixed } = this.effects;

    if (parallax) {
      imageMinHeight = sectionHeight * 2;
    } else if (fixed) {
      imageMinHeight = windowHeight + sectionHeight;
    }

    return Math.ceil(imageMinHeight);
  }

  getImageTranslate() {
    const { parallax } = this.effects;

    return parallax ? this.getImageParallaxTranslate() : null;
  }

  getImageParallaxTranslate() {
    const {
      windowHeight,
      sectionBound,
    } = this.data || {};
    const { imageData } = this;
    const imageRatio = imageData.naturalWidth / imageData.naturalHeight;
    const imageWidth = Math.max(
      imageData.naturalWidth,
      sectionBound.width,
    );
    const imageHeight = Math.max(
      this.imageMinHeight,
      imageWidth / imageRatio,
    );
    const maxTranslateY = imageHeight - sectionBound.height;
    const sectionAbsoluteTop = windowHeight - sectionBound.top;
    const translateY = ((sectionAbsoluteTop * maxTranslateY) / windowHeight)
      * PARALLAX_MULTIPLIER;

    return {
      y: translateY > maxTranslateY ? maxTranslateY : translateY,
    };
  }

  applyImageEffect({ action } = {}) {
    const { imageIsLoaded, effects: { active } = {} } = this;

    if (!imageIsLoaded || !active) return;

    const data = this.updateData();

    this.updateImageMinHeight({ action });

    if (!data.isVisible) return;

    this.updateImageTranslate();
  }

  handleScroll() {
    this.applyImageEffect();
  }

  handleResize() {
    this.applyImageEffect({ action: PARALLAX_UPDATE_ACTION });
  }
}

export default Parallax;
