export const FILE_DOWNLOAD_ATTR = '?dl=1';
export const DEFAULT_IMAGE = 'default.png';
export const NO_SRC = 'no_src';
export const STATIC_MARKERS = ['blocks/', 'themes/', 'static/'];
export const SOCIAL_LIST = {
  twitter: {
    label: 'Twitter',
    url: 'twitter.com/',
    icon: 'twitter',
    placeholder: 'Twitter account',
    getShareUrl: ({ href }) => `https://twitter.com/intent/tweet?text=${href}`,
  },
  fb: {
    label: 'Facebook',
    url: ['facebook.com/', 'fb.com/'],
    icon: 'facebook',
    placeholder: 'Facebook account',
    getShareUrl: ({ href }) => `https://www.facebook.com/sharer.php?u=${href}`,
  },
  instagram: {
    label: 'Instagram',
    url: 'instagram.com/',
    icon: 'instagram',
    placeholder: 'Instagram account',
    // no share
  },
  email: {
    label: 'Email',
    url: 'mailto:',
    icon: 'email',
    placeholder: 'Your email address',
    address: '',
    subject: 'Check out my awesome website',
    themeColors: true,
    getShareUrl: ({ href, address = '', subject = 'Check out my awesome website' }) => `mailto:${address}?subject=${subject}&body=${href}`,
  },
  blogger: {
    label: 'Blogger',
    url: 'blogspot.com/',
    sub: '.',
    icon: 'blogger',
    placeholder: 'Blogger account',
    getShareUrl: ({ href }) => `https://www.blogger.com/blog-this.g?u=${href}`,
  },
  deviantart: {
    label: 'Deviantart',
    url: 'deviantart.com/',
    sub: '.',
    icon: 'deviantart',
    placeholder: 'Deviantart account',
    // no share
  },
  dribble: {
    label: 'Dribbble',
    url: 'dribbble.com/',
    icon: 'dribbble',
    placeholder: 'Dribble account',
    // no share
  },
  flickr: {
    label: 'Flickr',
    url: 'flickr.com/',
    icon: 'flickr',
    placeholder: 'Flickr account',
    // no share
  },
  github: {
    label: 'Github',
    url: 'github.com/',
    icon: 'github',
    placeholder: 'Github account',
    // no share
  },
  googleplus: {
    label: 'Google',
    url: 'plus.google.com/',
    icon: 'googleplus',
    placeholder: 'Google plus account',
    getShareUrl: ({ href }) => `https://plus.google.com/share?url=${href}`,
  },
  linkedin: {
    label: 'Linkedin',
    url: 'linkedin.com/',
    icon: 'linkedin',
    placeholder: 'Linkedin account',
    getShareUrl: ({ href }) => `https://www.linkedin.com/shareArticle?url=${href}`,
  },
  pinterest: {
    label: 'Pinterest',
    url: 'pinterest.com/',
    icon: 'pinterest',
    placeholder: 'Pinterest account',
    getShareUrl: ({ href }) => `https://pinterest.com/pin/create/bookmarklet?url=${href}`,
  },
  tumblr: {
    label: 'Tumblr',
    url: 'tumblr.com/',
    sub: '.',
    icon: 'tumblr',
    placeholder: 'Tumblr account',
    getShareUrl: ({ href }) => `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${href}`,
  },
  vimeo: {
    label: 'Vimeo',
    url: 'vimeo.com/',
    icon: 'vimeo',
    placeholder: 'Vimeo account',
    // no share
  },
  vk: {
    label: 'Vk',
    url: 'vk.com/',
    icon: 'vk',
    placeholder: 'Vk account',
    getShareUrl: ({ href }) => `http://vk.com/share.php?url=${href}`,
  },
  youtube: {
    label: 'Youtube',
    url: 'youtube.com/',
    icon: 'youtube',
    placeholder: 'Youtube account',
    // no share
  },
  behance: {
    label: 'Behance',
    url: 'behance.net/',
    icon: 'behance',
    placeholder: 'Behance account',
    // no share
  },
  periscope: {
    label: 'Periscope',
    url: 'pscp.tv/',
    icon: 'periscope',
    placeholder: 'Periscope account',
    // no share
  },
  rss: {
    label: 'Rss',
    url: '', // just link same as email f.e.
    icon: 'rss',
    placeholder: 'Rss account',
    // no share
  },
  soundcloud: {
    label: 'Soundcloud',
    url: 'soundcloud.com/',
    icon: 'soundcloud',
    placeholder: 'Soundcloud account',
    // no share
  },
  spotify: {
    label: 'Spotify',
    url: 'spotify.com/',
    icon: 'spotify',
    placeholder: 'Spotify account',
    // no share
  },
  whatsapp: {
    label: 'WhatsApp',
    icon: 'whatsapp',
    getShareUrl: ({ href }) => `whatsapp://send?text=${href}`,
  },
  viber: {
    label: 'Viber',
    icon: 'viber',
    getShareUrl: ({ href }) => `viber://forward?text=${href}`,
  },
  telegram: {
    label: 'Telegram',
    icon: 'telegram',
    getShareUrl: ({ href }) => `https://t.me/share/url?url=${href}`,
  },
  fbmessenger: {
    label: 'Messenger',
    icon: 'fbmessenger',
    getShareUrl: ({ href }) => `fb-messenger://share?link=${href}`,
  },
  reddit: {
    label: 'Reddit',
    url: 'reddit.com/',
    icon: 'reddit',
    getShareUrl: ({ href }) => `https://reddit.com/submit?url=${href}`,
  },
  livejournal: {
    label: 'LiveJournal',
    url: 'livejournal.com/',
    icon: 'livejournal',
    placeholder: 'LiveJournal account',
    sub: '.',
    getShareUrl: ({ href }) => `http://www.livejournal.com/update.bml?event=${href}`,
  },
  pocket: {
    label: 'Pocket',
    icon: 'pocket',
    getShareUrl: ({ href }) => `https://getpocket.com/save?url=${href}`,
  },
  hackernews: {
    label: 'Hacker News',
    icon: 'hackernews',
    getShareUrl: ({ href }) => `https://news.ycombinator.com/submitlink?u=${href}`,
  },
};
