import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';
import { SLOGAN_WRAPPER_CLASS } from '../constants';

export default (elements) => {
  if (isEmpty(elements)) return elements;

  return elements.filter((el) => !dom.hasClass(el.firstElementChild, SLOGAN_WRAPPER_CLASS));
};
