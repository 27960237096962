import dom from '../../../wrapper/DomWrapper';
import { CART_CLASS, HEADER_LAYOUT_WRAPPER, LOGO_CLASS } from '../constants';

export default (selector) => {
  const onlyLogoAndCart = Array.from(dom.getCollection(`${HEADER_LAYOUT_WRAPPER}`, selector))
    .filter((el) => dom.hasClass(el, LOGO_CLASS) || dom.hasClass(el, CART_CLASS));

  const [firstElement] = onlyLogoAndCart;

  return dom.hasClass(firstElement, LOGO_CLASS);
};
