import isEmpty from 'lodash/isEmpty';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import {
  appendPinterestScript,
  getRetinaClass,
  generatePinterestURL,
} from './utils';

class PinterestButton extends WidgetWrapper {
  init = () => {
    const elPinterestButtonWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elPinterestButtonWidgetList)) return;

    elPinterestButtonWidgetList.forEach((widget) => {
      const options = JSON.parse(widget.getAttribute('data-options'));
      const { colorClass = '', imageURL = '' } = options;
      const retinaClass = getRetinaClass(colorClass);

      if (retinaClass) dom.addClass(widget, retinaClass);

      const href = generatePinterestURL(imageURL);
      widget.setAttribute('data-pin-href', href);
    });

    appendPinterestScript();
  }
}

export default PinterestButton;
