import isEmpty from 'lodash/isEmpty';
import dom from '../../../wrapper/DomWrapper';
import checkIsTheSameOrigin from './checkIsTheSameOrigin';

export default () => {
  const links = dom.getCollection('a') || [];
  const anchorLinks = [...links]
    .filter((link) => {
      if (!link) return false;

      const href = link.getAttribute('href');

      return href && href.indexOf('#') > -1;
    });

  if (isEmpty(anchorLinks)) {
    return {
      currentPageAnchors: [],
      externalPageAnchors: [],
    };
  }

  return anchorLinks.reduce((acc, curr) => {
    const href = curr.getAttribute('href');

    if (!href) return acc;

    if (href.startsWith('#')) {
      return {
        ...acc,
        currentPageAnchors: [...acc.currentPageAnchors, curr],
      };
    }

    const isHrefHasSameOrigin = checkIsTheSameOrigin(href);

    if (!isHrefHasSameOrigin) return acc;

    return {
      ...acc,
      externalPageAnchors: [...acc.externalPageAnchors, curr],
    };
  }, {
    currentPageAnchors: [],
    externalPageAnchors: [],
  });
};
