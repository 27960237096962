import dom from '../../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../../wrapper/WidgetWrapper';
import { initEcwidStorefront, connectEcwidScript, addGalleryListener } from '../../utils';

import {
  ECWID_WIDGET_STOREFRONT_TYPE,
  SPINNER_CONTAINER_ID,
  RETRY_CONTAINER_ID,
  RETRY_BUTTON_ID,
} from '../../constants';
import getStateValue from '../../../../../../helpers/getStateValue';

class CatalogPage extends WidgetWrapper {
  init() {
    this.elSpinner = dom.getElement(`#${SPINNER_CONTAINER_ID}`);
    this.elRetry = dom.getElement(`#${RETRY_CONTAINER_ID}`);
    this.elRetryButton = dom.getElement(`#${RETRY_BUTTON_ID}`);
    this.storeId = getStateValue(['ecommerce', 'storeId']);

    this.showSpinner();

    if (dom.window?.Ecwid) dom.window.Ecwid.OnPageLoaded.add(() => addGalleryListener());

    return initEcwidStorefront(this.selector, ECWID_WIDGET_STOREFRONT_TYPE)
      .then(() => {
        dom.off(this.elRetryButton, 'click', this.retryButtonHandler);
      })
      .catch(() => {
        this.showRetry();
        dom.on(this.elRetryButton, 'click', this.retryButtonHandler.bind(this));
      })
      .finally(() => {
        this.hideSpinner();
      });
  }

  reconnect() {
    connectEcwidScript(
      this.storeId,
      {
        onLoad: () => { this.hideRetry(); this.init(); },
      },
    );

    if (dom.getElement('#store-script')) dom.removeElement(dom.getElement('#store-script'));
  }

  retryButtonHandler() {
    if (!dom.window.Ecwid) {
      this.reconnect();
    } else {
      this.hideRetry();
      this.init();
    }
  }

  showRetry() {
    dom.show(this.elRetry);
  }

  hideRetry() {
    dom.hide(this.elRetry);
  }

  showSpinner() {
    dom.show(this.elSpinner);
  }

  hideSpinner() {
    dom.hide(this.elSpinner);
  }
}

export default CatalogPage;
