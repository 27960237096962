export const DASHBOARD_CONTAINER_ID = 'js-ecwid-native-wrapper';

export const SPINNER_CONTAINER_ID = 'js-ecwid-native-spinner';

export const RETRY_CONTAINER_ID = 'js-ecwid-native-retry';

export const RETRY_BUTTON_ID = 'js-ecwid-native-retry-button';

export const ECWID_WIDGET_STOREFRONT_TYPE = 'ProductBrowser';

export const ECWID_GALLERY_SELECTOR = '.details-gallery__wrap';
export const ECWID_GALLERY_MODAL_SELECTOR = '.ecwid-pswp';
export const ECWID_GALLERY_PICTURE_SELECTOR = '.details-gallery__picture';
export const ECWID_GALLERY_MORE_PICTURES_SELECTOR = '.details-gallery__thumb-more';
export const ECWID_GALLERY_NAV_LEFT_SELECTOR = '.pswp__button--arrow--left';
export const ECWID_GALLERY_NAV_RIGHT_SELECTOR = '.pswp__button--arrow--right';

export const ESC_KEY_CODE = 27;
