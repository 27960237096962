import getApiUrl from './getApiUrl';

export default (payload) => {
  const apiUrl = getApiUrl();

  return fetch(`${apiUrl}/api`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'access-control-allow-origin': '*',
    },
    body: JSON.stringify(payload),
  });
};
