import isBoolean from 'lodash/isBoolean';

import dom from '../../wrapper/DomWrapper';

import {
  MODAL_CLOSE_CLASS,
  MODAL_OVERLAY_CLASS,
  GDPR_CHECKBOX,
  GDPR_TEXT,
  ESC_KEY_CODE,
  GDPR_ANALYTICS_SWITCHER,
  HIDE_SCROLL_BODY_CLASS,
  FADE_OUT_ANIMATION_DURATION,
  COOKIE_MODAL_SAVE,
} from './constants';

class CookieModal {
  constructor(selector, manager) {
    this.selector = selector;
    this.elCookieModal = dom.getElement(selector);
    this.elCookieModalSaveButton = dom.getElement(COOKIE_MODAL_SAVE, this.elCookieModal);
    this.manager = manager;
  }

  init = () => {
    if (!this.elCookieModal) return;

    this.elAnalyticsSwitcher = dom.getElement(GDPR_ANALYTICS_SWITCHER, this.elCookieModal);
    this.elAnalyticsInput = dom.getElement(GDPR_CHECKBOX, this.elAnalyticsSwitcher);
    this.elAnalyticsText = dom.getElement(GDPR_TEXT, this.elAnalyticsSwitcher);
    this.analyticsValue = 0;
  };

  handleCookieModalClick = (event) => {
    const { target } = event;
    const shouldHideModal = dom.hasClass(target, MODAL_CLOSE_CLASS)
      || dom.hasClass(target, MODAL_OVERLAY_CLASS);

    if (shouldHideModal) this.hide();
  }

  show = async () => {
    dom.addClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);
    dom.fadeIn(this.elCookieModal);

    const analytics = await this.manager.getAnalyticsInitial();
    const analyticsBoolean = !!analytics;
    this.switchGdprSettings(analyticsBoolean);

    dom.on(this.elCookieModal, 'click', this.handleCookieModalClick);
    dom.on(this.elCookieModalSaveButton, 'click', this.saveValueToCookies);
    dom.on(this.elAnalyticsInput, 'change', () => this.switchGdprSettings());
    dom.on(dom.document, 'keydown', this.hideOnESCClick);
  }

  hide = () => {
    dom.fadeOut(
      this.elCookieModal,
      FADE_OUT_ANIMATION_DURATION,
      () => dom.removeClass(dom.document.body, HIDE_SCROLL_BODY_CLASS),
    );

    this.elCookieModal.removeEventListener('click', this.handleCookieModalClick);
    this.elCookieModalSaveButton.removeEventListener('click', this.saveValueToCookies);
    this.elAnalyticsInput.removeEventListener('change', () => this.switchGdprSettings());
    dom.document.removeEventListener('keydown', this.hideOnESCClick);
  }

  saveValueToCookies = () => {
    this.hide();
    this.manager.accept(this.analyticsValue);
  }

  hideOnESCClick = (event) => {
    if (event.keyCode === ESC_KEY_CODE) this.hide();
  }

  switchGdprSettings = (switchValue) => {
    if (isBoolean(switchValue)) this.elAnalyticsInput.checked = switchValue;

    const isChecked = this.elAnalyticsInput.checked;

    dom.addHtml(this.elAnalyticsText, isChecked ? 'On' : 'Off');
    this.analyticsValue = isChecked ? 1 : 0;
  }
}

export default CookieModal;
